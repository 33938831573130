@import '../variables.scss';

#login {
	background: $background;

	> form > div {
		background: $card;
		width: 400px;
		height: 450px;
		padding: 50px;
		@include elevation(5);
		border-radius: 5px;

		> h1 {
			margin-bottom: 30px;
			color: $textBold;
		}

		> div {
			height: 21px;
			margin-bottom: 30px;

			.error {
				color: $red;
			}
		}

		> span {
			margin-bottom: 20px;

			svg {
				fill: $icon;
			}
		}

		> input {
			display: none;
		}

		> button {
			margin-top: 65px;
		}

		> p {
			margin-top: 10px;
			color: #1890ff;
			&:hover {
				cursor: pointer;
			}
		}
	}
}
