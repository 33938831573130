@import 'variables.scss';

* {
	margin: 0;
	padding: 0;
}

html,
body,
#app,
#app > div {
	margin: 0;
	flex: 1;
	height: 100%;
	color: $text;
}

#app {
	.print {
		display: none;
	}

	.flex {
		display: flex;

		&.flex-dir {
			flex-direction: column;
		}

		&.flex-jc {
			justify-content: center;
		}

		&.flex-jsb {
			justify-content: space-between;
		}

		&.flex-ac {
			justify-content: center;
			align-items: center;
		}

		&.flex-aend {
			align-items: flex-end;
		}

		&.flex-1 {
			flex: 1;
		}
	}
}
